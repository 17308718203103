<template>
    <v-row>
     <v-col cols="6" sm="6" md="4" class="text-left mt-4">
     <v-toolbar-title class="pl-5">{{$t('title.transaction_player')}}</v-toolbar-title>
    <v-spacer></v-spacer>  
    </v-col>    
    
    <v-spacer></v-spacer>
    <v-col cols="6" sm="6" md="2" class="text-right pl-5">
      <v-menu
        v-model="v_startDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
        class="pl-5"
        >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :label="$t('input.start_date')"
            v-model="startDate"
            prepend-icon="mdi-calendar"
            readonly
            v-bind="attrs"
            v-on="on">
          </v-text-field>
        </template>
        <v-date-picker
          v-model="startDate"
          @input="v_startDate = false"
        >
      </v-date-picker>
      </v-menu>
    </v-col>
    <v-spacer></v-spacer>
    <v-col
      cols="6"
      sm="6"
      md="2"
      class="text-right pl-5 "
    >
      <v-menu
        v-model="v_endDate"
        :close-on-content-click="false"
        :nudge-right="100"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :label="$t('input.end_date')"
        v-model="endDate"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on">
      </v-text-field>
    </template>
      <v-date-picker
        v-model="endDate"
        @input="v_endDate = false"
      ></v-date-picker>
      </v-menu>
    </v-col>
    <v-col cols="8" sm="8" md="2" class="text-right pl-5">
      <v-text-field
        v-model="v_search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
         class="pl-5"
      />
    </v-col>
    <v-spacer></v-spacer>
    <v-col cols="4" sm="4" md="2" class="text-right pr-5 mb-5">
      <v-btn 
        class="white--text btn-custom mr-5"
        color="green darken-1"
        depressed
        @click="getData()"
      >
        SEARCH
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
    import { mapActions } from "vuex";
    import moment from "moment";
    export default {
        props: ["darkmode", "status", "params", "dataDelete", "userPermission"],
        data() {
            return {
            data: {
                descendingFormat: [true],
                sortByFormat: ['_id'],
                descending: true,
                sortBy: '_id',
                page: 1,
                rowsPerPage: 10,
                fields: ['module_name', 'user_name','module_bet_id'],
                search: '',
                startDate:  moment().format("YYYY-MM-DD"),
                endDate:  moment().format("YYYY-MM-DD"),
            },
            startDate:  moment().format("YYYY-MM-DD"),
            endDate:  moment().format("YYYY-MM-DD"),
            v_startDate: null,
            v_endDate: null,
            v_search:""
            };
        },
        watch: {
        listModule: function() {},
            time: function(newVal) {
            this.startDate = newVal.startDate;
            this.endDate = newVal.endDate;
            } 
        },
        methods: {
            async createDialog() {
                this.fetchPopUp(true)
            },
            async getData() {
                this.data.startDate = this.startDate;
                this.data.endDate = this.endDate;
                this.data.search = this.v_search;
                console.log("this.data",this.data);
                // let preData = {
                //     data : this.data,
                //     reportUrl : 'tranc-player/getAll'
                // }
                
                this.fetchListTransactionPlayer(this.data).then(() => {
                        let date = {
                        startDate : this.startDate,
                        endDate : this.endDate,
                        search: this.v_search
                        }
                    this.$emit("child-checkbox", date);
                    })
            },
            ...mapActions("$_transactionPlayer", ["fetchListTransactionPlayer"]),
        },
    };
</script>

<style lang="scss" scoped>
</style>
